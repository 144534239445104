
import { get, omit, set } from "lodash";
import PolicyInfo from "@/components/User/PolicyInfo.vue";
import { getPropertyChangeRequestDefaults } from "@/helpers/getPropertyChangeRequestDefaults";
import PropertyChangeRequestActions from "@/components/User/ChangeRequestActions.vue";
import {
  propertyChangeRequestMapActions,
  propertyChangeRequestMapGetters,
  propertyChangeRequestMapMutations,
  propertyChangeRequestMapState
} from "@/store/modules/propertychangerequest";
import * as types from "@/store/mutation-types";
import { checkObjectForFalsyValues, validateFields } from "@/helpers";
import { cloneDeep } from "lodash";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import VueWithMixins from "@/helpers/mixins";
import ChangeRequestMixin from "../../ChangeRequests/ChangeRequestMixin";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
interface IData {
  loading: boolean;
  error: string;
  message: string;
  isAdmin: boolean;
  hasValidCredentials: boolean;
  isAgentFieldValid: boolean;
  isPolicyInfoValid: boolean;
  isInsuredFieldsValid: boolean;
  isActionFieldValid: boolean;
  disablePolicyInfoComponent: boolean;
  loadingText: string;
  propertyChangeRequestId: string;
  disableSubmitOnMortgageCheck: boolean;
  insuredInfo: Record<string, any>;
  modalButtonYesClick: boolean;
  insuredKeys: string[];
}

export default VueWithMixins(ChangeRequestMixin).extend({
  components: {
    PolicyInfo,
    PropertyChangeRequestActions,
    ChangeRequestInsured: () =>
      import("@/components/User/ChangeRequestInsured.vue"),
    CustomAlert
  },
  mixins: [ActivitiesMixin],
  data(): IData {
    return {
      loading: false,
      error: "",
      message: "",
      isAdmin: false,
      hasValidCredentials: false,
      isAgentFieldValid: false,
      isPolicyInfoValid: false,
      isInsuredFieldsValid: false,
      isActionFieldValid: false,
      disablePolicyInfoComponent: false,
      loadingText: "",
      propertyChangeRequestId: "",
      disableSubmitOnMortgageCheck: false,
      insuredInfo: {},
      modalButtonYesClick: false,
      insuredKeys: ["firstName", "lastName"]
    };
  },
  created() {
    this.setEdit(getPropertyChangeRequestDefaults({}));
    this.editingField = this.editField;
  },
  destroyed() {
    this.setState({ key: "editing", value: null });
  },
  methods: {
    ...propertyChangeRequestMapActions([
      "createPropertyChangeRequest",
      "updatePropertyChangeRequest"
    ]),
    ...propertyChangeRequestMapMutations({
      editField: types.SET_EDIT_PROPERTY_CHANGE_REQUEST,
      setEdit: types.SET_EDIT,
      setState: types.SET_STATE
    }),
    handleDisableSubmitButtonOnMortgageCheck($event: any) {
      this.disableSubmitOnMortgageCheck = $event;
    },
    editPolicyInsuredInfo() {
      this.propertyChangeRequestId = "";
      this.disablePolicyInfoComponent = false;
      this.insuredInfo.changeRequestMade = false;
      const insuredKey = [
        "lastName",
        "firstName",
        "insuredAddress.houseNumber",
        "insuredAddress.streetName",
        "insuredAddress.zipCode",
        "insuredAddress.city",
        "insuredAddress.state"
      ];
      for (let i = 0; i < insuredKey.length; i++) {
        this.handleChangeRequestCreation({
          fieldKey: `data.insured.${insuredKey[i]}`,
          value: ""
        });
      }
    },
    attachmentData(data: any) {
      this.uploadedFile = [...this.uploadedFile, ...data];
      this.temporalMapFileData(data, this.editing);
    },
    updateIsValid() {
      this.hasValidCredentials = this.$refs
        ? validateFields(this.$refs)
        : false;
    },
    setAddPropertyChangeOption(data: any): any {
      const userId = this.$getCurrentUser._id;
      let status = "Not Submitted";
      if (data == "Submit") {
        status = "Submitted";
        this.editField({ key: "submittedOn", value: new Date() });
      }
      this.editField({ key: "status", value: status });
      this.editField({ key: "userId", value: userId });
    },
    async save() {
      this.setAddPropertyChangeOption("save");
      await this.create();
    },
    async doSubmit() {
      if (
        !this.editing?.data.actions?.limitAction &&
        !this.editing?.data.actions?.mortgageAction &&
        !this.editing?.data.actions?.endorsementAction
      ) {
        this.error =
          "Please select and provide details for at least one section to be able to submit this request";
        this.$emit("scrollTop");
        return;
      }
      this.setAddPropertyChangeOption("Submit");
      await this.create();
    },
    onToolbarItemSelected(action: string): void {
      switch (action) {
        case "saveChangeRequest":
          this.save();
          break;
        case "submitChangeRequest":
          this.doSubmit();
          break;
        case "cancel":
          this.goBackToListView("/propertychangerequests");
          break;
      }
    },
    async sendFormData() {
      try {
        if (this.editing) {
          if (this.propertyChangeRequestId) {
            await this.doUpdate();
          } else {
            let updatedFields = cloneDeep(this.editing);
            if (this.attachmentIds && this.attachmentIds.length) {
              set(updatedFields, "attachments", this.attachmentIds);
            }
            if (!updatedFields?.data?.mortgage?.payor) {
              updatedFields.data.mortgage = omit(updatedFields.data.mortgage, [
                "payor"
              ]);
            }
            if (!updatedFields?.data?.mortgage?.mortgagePosition) {
              updatedFields.data.mortgage = omit(updatedFields.data.mortgage, [
                "mortgagePosition"
              ]);
            }
            if (!updatedFields?.data?.mortgage?.payPlanType) {
              updatedFields.data.mortgage = omit(updatedFields.data.mortgage, [
                "payPlanType"
              ]);
            }
            const response = await this.createPropertyChangeRequest(
              updatedFields
            );
            this.uploadedFile = [];
            const { id, submittedBy } = response;
            this.propertyChangeRequestId = id;
            this.submittedBy = submittedBy;
            this.$appNotifySuccess(`Policy Change Request Saved`);
          }
        }
      } catch (error) {
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async create(): Promise<void> {
      if (Object.keys(this.editing as any).length === 0) {
        return;
      }
      try {
        this.loadingText = "loading...";
        this.loading = true;

        if (!this.uploadedFile.length) {
          return await this.sendFormData();
        }
        //CHECK IF AT LEAST ONE OF THE SECTIONS IS VALID
        let response = await this.createAtlasfile(
          this.createFormData(this.uploadedFile as any)
        );
        this.mapFileData(response, this.editing);

        if (response.length) {
          const attachmentIds = response.map((file: any) => file._id);
          this.attachmentIds = [...this.attachmentIds, ...attachmentIds];
        } else {
          this.attachmentIds = [...this.attachmentIds, response._id];
        }
        await this.sendFormData();
      } catch (e) {
        this.error = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loadingText = "";
        this.loading = false;
        this.$emit("scrollTop");
      }
    },
    actionsFieldsValid(data: any) {
      this.isActionFieldValid = data;
    },
    // check if agent field is valid
    agentFieldValid(data: any) {
      this.isAgentFieldValid = data;
    },
    // check if policyInfo field is valid
    policyInfoFieldValid(data: any) {
      this.isPolicyInfoValid = data;
    },
    // check if insured Field is valid
    insuredFieldsValid(data: any) {
      this.isInsuredFieldsValid = data;
    },
    async doUpdate() {
      try {
        let updateData: any = cloneDeep(this.editing);
        delete updateData.data?.agentInfo;
        let updatedFields = { ...updateData, submittedBy: this.submittedBy };
        if (this.attachmentIds && this.attachmentIds.length) {
          set(updatedFields, "attachments", this.attachmentIds);
        }
        if (!updatedFields?.data?.mortgage?.payor) {
          updatedFields.data.mortgage = omit(updatedFields.data.mortgage, [
            "payor"
          ]);
        }
        if (!updatedFields?.data?.mortgage?.payPlanType) {
          updatedFields.data.mortgage = omit(updatedFields.data.mortgage, [
            "payPlanType"
          ]);
        }
        await this.updatePropertyChangeRequest({
          id: this.propertyChangeRequestId,
          update: updatedFields
        });
        this.$appNotifySuccess("Policy Change Request submitted");
        this.goBackToListView("/propertychangerequests");
      } catch (error) {
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    setMailingAndLocAddresses(addressesArray: any[]) {
      if (addressesArray.length) {
        const insuredAddressKey = [
          "houseNumber",
          "streetName",
          "zipCode",
          "city",
          "state"
        ];
        for (let m = 0; m < addressesArray.length; m++) {
          for (let i = 0; i < insuredAddressKey.length; i++) {
            const key = m == 0 ? "insuredAddress" : "location";

            this.handleChangeRequestCreation({
              fieldKey: `data.insured.${key}.${insuredAddressKey[i]}`,
              value: get(addressesArray[m], `${insuredAddressKey[i]}`, "")
            });
          }
        }
      }
    }
  },
  computed: {
    ...propertyChangeRequestMapState(["editing", "makingApiRequest"]),
    ...propertyChangeRequestMapGetters([
      "getPropertyChangeRequestsFromStore",
      "getEditingPropertyChangeRequest"
    ]),
    checkValidMortgageAction(): boolean {
      let response = true;
      if (
        this.disableSubmitOnMortgageCheck ||
        this.editing?.data.actions?.mortgageAction
      ) {
        const mortgage: Record<string, any> = get(
          this.editing?.data,
          "mortgage",
          {}
        );
        const mortgageKeys = ["address", "mortgagePosition"];
        const mortgageAddressKeys = ["city", "state", "zipCode"];
        const hasValidMortgage = mortgageKeys.every((key: string) => {
          return !checkObjectForFalsyValues(mortgage, key);
        });
        const hasValidMortgageAddress = mortgageAddressKeys.every(
          (key: string) =>
            !checkObjectForFalsyValues(mortgage.mortgageAddress, key)
        );
        response = hasValidMortgage && hasValidMortgageAddress;
      }
      return response;
    },
    disablePrimaryButton(): boolean {
      return Boolean(
        this.isActionFieldValid ||
          !this.checkValidMortgageAction ||
          !this.hasValidMortgageBillInsured(this.editing) ||
          !this.hasSelectedMortgageAction(this.editing) ||
          (!this.actionIsDeleteMortgage(this.editing) &&
            this.policyIsLapsedAndHasNoAttachments(this.editing))
      );
    },
    primaryToolbar(): Record<string, any> | null {
      if (
        this.insuredInfo &&
        this.insuredInfo.changeRequestMade &&
        !this.propertyChangeRequestId
      ) {
        return {
          text: "Save",
          key: "saveChangeRequest",
          disabled: this.disablePrimaryButton,
          loading: this.loading
        };
      } else if (this.propertyChangeRequestId) {
        {
          return {
            text: "Submit",
            key: "submitChangeRequest",
            disabled: this.disablePrimaryButton,
            loading: this.loading
          };
        }
      }
      return null;
    },
    secondaryToolbar(): any {
      if (this.editing) {
        return {
          text: "Actions",
          key: "actions",
          subItems: [{ title: "Cancel", command: "cancel" }]
        };
      }
      return undefined;
    },
    checkIfAtLeastOneActionHasBeenSelected(): boolean {
      return (
        Boolean(this.editing?.data.actions?.limitAction) ||
        Boolean(this.editing?.data.actions?.mortgageAction) ||
        Boolean(this.editing?.data.actions?.endorsementAction)
      );
    },
    queryOverride(): any {
      return {
        "data.policyChangeRequestId": this.propertyChangeRequestId,
        activityType: "PolicyChangeRequestActivity"
      };
    }
  }
});
