var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"errorMessage":_vm.error,"successMessage":_vm.message,"showSearch":false,"topActionButtonSecondary":_vm.secondaryToolbar,"topActionButtonPrimary":_vm.primaryToolbar},on:{"toolbarSelectItem":_vm.onToolbarItemSelected,"errorMessageClosed":function($event){_vm.error = ''}}},[(
      _vm.$getCurrentUser &&
        _vm.$getCurrentUser.role == 'admin' &&
        _vm.propertyChangeRequestId
    )?_c('TabMenu',{staticClass:"mb-12",attrs:{"menuItems":_vm.menuItems,"selectedTabKey":_vm.selectedTabKey},on:{"tabClicked":_vm.onTabItemClick}}):_vm._e(),(_vm.editing && !_vm.showActivities)?_c('div',[_c('el-form',[_c('div',[(
            _vm.editing &&
              _vm.editing.data &&
              _vm.editing.data.insured &&
              _vm.editing.data.insured.lastName &&
              _vm.editing.data.insured.firstName
          )?_c('div',{staticClass:"cursor-pointer text-base text-atlas-blue hover:font-medium flex items-center justify-end my-5",on:{"click":_vm.editPolicyInsuredInfo}},[_c('div',[_c('i',{staticClass:"el-icon-edit"}),_vm._v("Edit")])]):_vm._e(),(
            _vm.insuredInfo.isExempted &&
              _vm.editing?.data?.isLapsed &&
              !_vm.editing?.attachments?.length
          )?_c('custom-alert',{attrs:{"title":"Please Note","message":'Policy is lapsed and would require documentation supporting the new cancellation date before submission.',"show":true,"dismissible":false}}):_vm._e(),(_vm.editing && _vm.editing.data && _vm.editing.data.policyInfo)?_c('PolicyInfo',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingText),expression:"loadingText"}],ref:"propertyChangeReqPolicyInfoComponentRef",attrs:{"element-loading-text":_vm.loadingText,"isChangeRequest":true,"policyInfo":_vm.editing.data.policyInfo,"disabled":_vm.disablePolicyInfoComponent},on:{"policyInfoFieldValid":_vm.policyInfoFieldValid,"setLoadingText":function($event){_vm.loadingText = $event},"updateChangeRequestData":function($event){return _vm.handleChangeRequestCreation($event, 'data.policyInfo')},"paddedPolicyNumber":_vm.setPaddedPolicyNumber,"sendBackInsuredInfo":_vm.setInsuredInfo,"sendBackInsuredMailingAndLocAddress":_vm.setMailingAndLocAddresses}}):_vm._e(),(
            _vm.editing &&
              _vm.editing.data &&
              _vm.editing.data.insured &&
              _vm.editing.data.insured.lastName &&
              _vm.editing.data.insured.firstName
          )?_c('ChangeRequestInsured',{attrs:{"insuredData":_vm.editing?.data?.insured,"type":"new","changeRequestType":"POLICY_CHANGE_REQUEST"}}):_vm._e()],1)]),(
        _vm.editing &&
          _vm.editing.data.insured.lastName &&
          _vm.editing.data.insured.firstName
      )?_c('PropertyChangeRequestActions',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingText),expression:"loadingText"}],attrs:{"element-loading-text":_vm.loadingText,"propertyChangeRequestData":_vm.editing},on:{"actionsFieldsValid":_vm.actionsFieldsValid,"attachmentData":_vm.attachmentData,"updateChangeRequestData":function($event){return _vm.handleChangeRequestCreation($event, '')},"disableMortgageSubmitButton":_vm.handleDisableSubmitButtonOnMortgageCheck,"handleAttachmentDelete":_vm.attachmentDeleteHandler}}):_vm._e(),(_vm.editing?.status && _vm.insuredInfo.changeRequestMade)?_c('span',{staticClass:"mb-20 block",staticStyle:{"float":"right"}},[_vm._v("Status : "+_vm._s(`${_vm.propertyChangeRequestId ? "Saved" : _vm.editing?.status}`))]):_vm._e()],1):_c('ActivitiesView',{attrs:{"queryOverride":_vm.queryOverride},scopedSlots:_vm._u([{key:"default",fn:function({ activity }){return [_c('ActivitiesTimelineItem',{attrs:{"activity":activity}})]}}])}),_c('ModalMessage',{attrs:{"name":"lapsedPolicyModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Lapsed Policy","description":_vm.modalMessage,"buttons":[
      {
        text: `Yes`,
        classList: ['border-atlas-blue bg-white text-atlas-blue'],
        click: () => _vm.handleModalButtonYesClick()
      },
      {
        text: `No`,
        click: () => _vm.handleModalButtonNoClick()
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }